import { getQueryStringParameter } from 'growth-payments-core/query-string/utils';
import { isComingFromCommerceHubUserGuide } from 'growth-payments-core/urls/utils';
import { CONVERT_DEAL_TO_TOOL_INDEX_PAGE_TOUR_ID } from './config';
import { useTourStatus } from '../../hooks/useTourStatus';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback } from 'react';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
const useHasConvertDealToToolRelatedQueryParams = () => {
  return !!getQueryStringParameter('dealId') && !!getQueryStringParameter('tool');
};
export const useConvertDealToToolOnboardingTour = () => {
  const hasConvertDealToToolRelatedQueryParams = useHasConvertDealToToolRelatedQueryParams();
  const {
    loading
  } = useTourStatus(CONVERT_DEAL_TO_TOOL_INDEX_PAGE_TOUR_ID);
  const shouldStartConvertDealToToolOnboardingTour = !loading && hasConvertDealToToolRelatedQueryParams && isComingFromCommerceHubUserGuide();
  return shouldStartConvertDealToToolOnboardingTour;
};
export const useOpenDealPreviewSidebarOnLoad = objectTypeId => {
  const hasConvertDealToToolRelatedQueryParams = useHasConvertDealToToolRelatedQueryParams();
  const sendPreviewSidebarMessage = useSendCrmMessageTopic(TOPIC_NAMES.PREVIEW_OBJECT);
  return useCallback(() => {
    if (objectTypeId === DEAL_TYPE_ID && hasConvertDealToToolRelatedQueryParams) {
      const dealId = getQueryStringParameter('dealId');
      sendPreviewSidebarMessage({
        objectId: dealId,
        objectTypeId
      });
    }
  }, [objectTypeId, hasConvertDealToToolRelatedQueryParams, sendPreviewSidebarMessage]);
};