import { useEffect, useState } from 'react';
import { TourCompletionStatus } from 'onboarding-tours-client/constants';
import { getTourStatus } from 'onboarding-tours-client/onboardingToursClient';
import Raven from 'raven-js';
export const useTourStatus = tourId => {
  const [loading, setLoading] = useState(true);
  const [tourStatus, setTourStatus] = useState(TourCompletionStatus.INCOMPLETE);
  useEffect(() => {
    setLoading(true);
    getTourStatus(tourId).then(({
      status
    }) => {
      setTourStatus(status);
    }).catch(() => {
      Raven.captureException(new Error('Failed to fetch tour status'));
    }).finally(() => {
      setLoading(false);
    });
  }, [tourId]);
  return {
    loading,
    tourStatus
  };
};